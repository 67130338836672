import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import{GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { BufferGeometry, Color, LOD, Mesh, Texture, Vector3 } from 'three'
import {loadArrayPost,select,select1,select2,select3,select4,select5,select6,onChange,onChange1,onChange2,onChange3,onChange4,onChange5,onChange6,Dispose} from './main.js'
import * as TWEEN from "@tweenjs/tween.js";
// import { inspect } from 'util'
/**
 * Array
 */
const canvas = document.querySelector('canvas.webgl')
let scene, gltfLoader,dracoLoader,cubeTextureLoader,sizes, camera, renderer, controls, mixer, clock,directionalLight
let debugObject = {}
init()

function disposeNode (node)
{
    if (node instanceof THREE.Mesh)
    {
        if (node.geometry)
        {
            node.geometry.dispose ();
        }

        if (node.material)
        {
            if (node.material instanceof THREE.Mesh)
            {
                $.each (node.material.materials, function (idx, mtrl)
                {
                    if (mtrl.map)               mtrl.map.dispose ();
                    if (mtrl.lightMap)          mtrl.lightMap.dispose ();
                    if (mtrl.bumpMap)           mtrl.bumpMap.dispose ();
                    if (mtrl.normalMap)         mtrl.normalMap.dispose ();
                    if (mtrl.specularMap)       mtrl.specularMap.dispose ();
                    if (mtrl.envMap)            mtrl.envMap.dispose ();
                    if (mtrl.alphaMap)          mtrl.alphaMap.dispose();
                    if (mtrl.aoMap)             mtrl.aoMap.dispose();
                    if (mtrl.displacementMap)   mtrl.displacementMap.dispose();
                    if (mtrl.emissiveMap)       mtrl.emissiveMap.dispose();
                    if (mtrl.gradientMap)       mtrl.gradientMap.dispose();
                    if (mtrl.metalnessMap)      mtrl.metalnessMap.dispose();
                    if (mtrl.roughnessMap)      mtrl.roughnessMap.dispose();

                    mtrl.dispose ();    // disposes any programs associated with the material
                });
            }
            else
            {
                if (node.material.map)              node.material.map.dispose ();
                if (node.material.lightMap)         node.material.lightMap.dispose ();
                if (node.material.bumpMap)          node.material.bumpMap.dispose ();
                if (node.material.normalMap)        node.material.normalMap.dispose ();
                if (node.material.specularMap)      node.material.specularMap.dispose ();
                if (node.material.envMap)           node.material.envMap.dispose ();
                if (node.material.alphaMap)         node.material.alphaMap.dispose();
                if (node.material.aoMap)            node.material.aoMap.dispose();
                if (node.material.displacementMap)  node.material.displacementMap.dispose();
                if (node.material.emissiveMap)      node.material.emissiveMap.dispose();
                if (node.material.gradientMap)      node.material.gradientMap.dispose();
                if (node.material.metalnessMap)     node.material.metalnessMap.dispose();
                if (node.material.roughnessMap)     node.material.roughnessMap.dispose();

                node.material.dispose ();   // disposes any programs associated with the material
            }
        }
    }
}   // disposeNode

function disposeHierarchy (node,callback)
{
    for (var i = node.children.length - 1; i >= 0; i--)
    {
        var child = node.children[i];
        disposeHierarchy (child, callback);
        callback (child);
    }
}

function init(){
/**
 * Loader
 */
// Canvas
// Scene
 scene = new THREE.Scene()

 gltfLoader = new GLTFLoader()
 dracoLoader = new DRACOLoader()
 dracoLoader.setDecoderPath('/draco/')
 gltfLoader.setDRACOLoader(dracoLoader)
 dracoLoader.preload()
 cubeTextureLoader = new THREE.CubeTextureLoader()

// let DisposeScene = document.querySelector('.btn2')
// DisposeScene.addEventListener('click', ()=>{
//     Dispose();
// })

 sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

 camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 1000)
camera.position.set(0, 0, -1)
camera.position.setLength(0.65);
scene.add(camera)

 renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true


})  
renderer.dispose()
clock = new THREE.Clock();  
/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

directionalLight = new THREE.DirectionalLight('#ffffff',1)
directionalLight.position.set(1,1,1)
directionalLight.castShadow = true
directionalLight.shadow.camera.far = 15
directionalLight.shadow.mapSize.set(1024,1024)
scene.add(directionalLight)
debugObject.envMapIntensity=1.5
// gui.add(debugObject, 'envMapIntensity').min(0).max(10).step(0.001).onChange(()=>{
//     updateAllMaterials()
// })





/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/Lights_back/px.png',
    '/textures/Lights_back/nx.png',
    '/textures/Lights_back/py.png',
    '/textures/Lights_back/ny.png',
    '/textures/Lights_back/pz.png',
    '/textures/Lights_back/nz.png'
])
environmentMap.dispose()
const color69= new THREE.Color('#FFFFFF')
environmentMap.encoding= THREE.sRGBEncoding

scene.environment = environmentMap
scene.background = color69
/**
 * Buttons
 */
 
//  select.onchange = onChange;
//  onChange();


// Controls
 controls = new OrbitControls(camera, canvas)
controls.enableDamping = true



/**
 * Renderer
 */

// const newcontrols = new OrbitControls( camera, renderer.domElement );
// newcontrols.addEventListener( 'change', render );

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights= true
renderer.outputEncoding= THREE.sRGBEncoding
renderer.toneMapping= THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1.5
renderer.shadowMap.enabled= true
renderer.shadowMap.type= THREE.PCFSoftShadowMap


// var select = document.getElementById('leftmodels');
// var rightmodel = select.options[select.selectedIndex].innerHTML;
// console.log(rightmodel)
/**
 * Models
 */

//  let LoadBtn = document.querySelector('.btn');
//  LoadBtn.addEventListener('click', () => {
//      select.onChange= onChange();
//      select1.onChange= onChange1();
//      select2.onChange= onChange2();
//      select3.onChange= onChange3();
//      select4.onChange= onChange4();
//      select5.onChange= onChange5();
//      select6.onChange= onChange6();
     
}

   
/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child)=>
    {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {

            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true

        }
    })
}  



//  }) 
let partsArray = loadArrayPost
// var select;      
//  select = document.getElementById('leftmodels')
// const cleanMaterial = material => {
          
//     material.dispose()

//     // dispose textures
//     for (const key of Object.keys(material)) {
//         const value = material[key]
//         if (value && typeof value === 'object' && 'minFilter' in value) {
            
//             value.dispose()
//         }
//     }
// }
// scene.traverse(object => {
//     if (!object.isMesh) return
    
   
//     object.geometry.dispose()

//     if (object.material.isMaterial) {
//         cleanMaterial(object.material)
//     } else {
//         // an array of materials
//         for (const material of object.material) cleanMaterial(material)
//     }
// })

loadParts(partsArray)
// let LoadPrts = document.querySelector('.btn1');
//     LoadPrts.addEventListener('click', () => {
//         loadParts(partsArray)
        
    // })
    animate();       
    // const cleanMaterial = material => {
          
    //     material.dispose()
    
    //     // dispose textures
    //     for (const key of Object.keys(material)) {
    //         const value = material[key]
    //         if (value && typeof value === 'object' && 'minFilter' in value) {
                
    //             value.dispose()
    //         }
    //     }
    // }
    // scene.traverse(object => {
    //     if (!object.isMesh) return
        
       
    //     object.geometry.dispose()
    
    //     if (object.material.isMaterial) {
    //         cleanMaterial(object.material)
    //     } else {
    //         // an array of materials
    //         for (const material of object.material) cleanMaterial(material)
    //     }
    // })
    
    
    
    renderer.dispose()
    renderer.renderLists.dispose()
   
   
export default function loadParts(loadArray){
    init()
let loadArrayGltf = []
// var model,model1,model2,model3,model4,model5,model6;
scene.traverse( function(child) {
    if (child.isMesh) {
        scene.remove(child)
   
      }
    })
    
   
   
        const cleanMaterial = material => {
          
            material.dispose()
        
            // dispose textures
            for (const key of Object.keys(material)) {
                const value = material[key]
                if (value && typeof value === 'object' && 'minFilter' in value) {
                    
                    value.dispose()
                }
            }
        }
        scene.traverse(object => {
            if (!object.isMesh) return
            
           
            object.geometry.dispose()
        
            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })
        scene.remove(scene.children[0]); 
        // scene.remove(model1)
        // scene.dispose(scene.children[0])
        // scene.material.dispose()
        // scene.geometry.dispose()ss
        
        renderer.dispose()
      
        renderer.renderLists.dispose()
        
    //     disposeNode(model)
    //    disposeHierarchy(model,disposeNode)
    
  
    renderer.dispose()

scene.traverse(object => {
	if (!object.isMesh) return
	
	// console.log('dispose geometry!')
	object.geometry.dispose()


})

// console.log(gltf-transform inspect my-MODEL_1.glb)

    
    // renderer.render(scene, camera)
    
   

    function inputGltf() {
        for (let i = 0; i < loadArray.length; i++) {
            if (loadArray[i]) {
              loadArrayGltf.push("/models/Glbs/"  + loadArray[i] + ".glb")
              
            }

        }
        
    }

    

    /**
     * Group
     */
     inputGltf();
    for (let i = 0; i < loadArrayGltf.length; i++) {
        if (loadArrayGltf[i]) {
            loadArrayGltf[i]+", ";
        }

        if(!loadArrayGltf[0].includes(" ")){
            
            gltfLoader.load(
                loadArrayGltf[0],


                (gltf) =>
            {
                
                
        // const scene = gltf.scene;
        
                
              
                
                gltf.scene.traverse( function ( child )
            {
                if ( child.isMesh )
                {
                    const box = new THREE.Box3().setFromObject(child);

                    const boxSize = box.getSize(new THREE.Vector3()).length();

                    ;
                    }
            } );

            // gltf.scene.traverse( function ( child )
            // {
            //     if ( child.isObject3D && loadArrayPost.includes(child) )
            //     {

            //     }
            // }  )
            
                    
                    // const root = gltf.scene;
                    // const box = new THREE.Box3().setFromObject(root);
                    // const boxSize = box.getSize(new THREE.Vector3()).length();
                    // const boxCenter = box.getCenter(new THREE.Vector3());
                    const scena=gltf.scene
       
        disposeHierarchy(scena,disposeNode)
  
    
      
                    gltf.scene.scale.set(2.439,2.439,2.439)
            
            gltf.scene.scale.set(2.439,2.439,2.439)
            gltf.scene.position.set(0.244,0,0)
            gltf.scene.rotation.set(Math.PI/2,Math.PI ,0)
                    // console.log(box)


         
          
                    
        scene.add(gltf.scene)
        renderer.dispose()
        const cleanMaterial = material => {
          
            material.dispose()
        
            // dispose textures
            for (const key of Object.keys(material)) {
                const value = material[key]
                if (value && typeof value === 'object' && 'minFilter' in value) {
                    
                    value.dispose()
                }
            }
        }
        scena.traverse(object => {
            if (!object.isMesh) return
            
            object.geometry.dispose()
        
            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })
        
        

        
            //console.log(scene.children)
            disposeHierarchy(gltf.scene,disposeNode)

            updateAllMaterials()
        }
    )
    
        }
    }

if(!loadArrayGltf[1].includes(" ")){
    gltfLoader.load(
        loadArrayGltf[1],
    
    
       (gltf) =>
    {
        

        
    
       gltf.scene.traverse( function ( child )
    {
       if ( child.isMesh )
       {
        
                    // child.texture.dispose();
           const box = new THREE.Box3().setFromObject(child);
    
           const boxSize = box.getSize(new THREE.Vector3()).length();
    
           ;
           }
    } );
    
    // gltf.scene.traverse( function ( child )
    // {
    //     if ( child.isObject3D && loadArrayPost.includes(child) )
    //     {
    
    //     }
    // }  )
    
    const scena=gltf.scene
        //    const root = gltf.scene;
        //    const box = new THREE.Box3().setFromObject(root);
        //    const boxSize = box.getSize(new THREE.Vector3()).length();
        //    const boxCenter = box.getCenter(new THREE.Vector3());
           gltf.scene.scale.set(2.380,2.380,2.380)
           gltf.scene.rotation.set(0,Math.PI,0)
             gltf.scene.position.set(0.244,0,0)
             
           
    
    
    
    scene.add(gltf.scene)
    renderer.dispose()
        const cleanMaterial = material => {
          
            material.dispose()
        
            // dispose textures
            for (const key of Object.keys(material)) {
                const value = material[key]
                if (value && typeof value === 'object' && 'minFilter' in value) {
                    
                    value.dispose()
                }
            }
        }
        scena.traverse(object => {
            if (!object.isMesh) return
            
           
            object.geometry.dispose()
        
            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })
    disposeHierarchy(gltf.scene,disposeNode)
    renderer.dispose()
    //console.log(scene.children)
    updateAllMaterials()
    })
}



if(!loadArrayGltf[2].includes(" ")){
    gltfLoader.load(
        loadArrayGltf[2],
    
    
       (gltf) =>
    {
    
        
    
    // gltf.scene.traverse( function ( child )
    // {
    //     if ( child.isObject3D && loadArrayPost.includes(child) )
    //     {
    
    //     }
    // }  )
    
    
        //    const root = gltf.scene;
        //    const box = new THREE.Box3().setFromObject(root);
        //    const boxSize = box.getSize(new THREE.Vector3()).length();
        //    const boxCenter = box.getCenter(new THREE.Vector3());
        //    gltf.scene.scale.set(2.439,2.439,2.439)
       const scena = gltf.scene
        gltf.scene.rotation.set(0,Math.PI,0)
           gltf.scene.position.set(0.244,0,0)
           
    
           //console.log(box)
    // gltf.scene.scale.set(40,40,40)
    
    scene.add(gltf.scene)
    
    disposeHierarchy(gltf.scene,disposeNode)
    renderer.dispose()
    const cleanMaterial = material => {
          
        material.dispose()
    
        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === 'object' && 'minFilter' in value) {
                
                value.dispose()
            }
        }
    }
    scena.traverse(object => {
        if (!object.isMesh) return
        
       
        object.geometry.dispose()
    
        if (object.material.isMaterial) {
            cleanMaterial(object.material)
        } else {
            // an array of materials
            for (const material of object.material) cleanMaterial(material)
        }
    })
    // console.log(gltf.scene);
    //console.log(scene.children)
    updateAllMaterials()
    }
    )
}


if(!loadArrayGltf[3].includes(" ")){
    gltfLoader.load(
        loadArrayGltf[3],
    
    
       (gltf) =>
    {
    
 
    
    
       gltf.scene.traverse( function ( child )
    {
       if ( child.isMesh )
       {
           const box = new THREE.Box3().setFromObject(child);
    
           const boxSize = box.getSize(new THREE.Vector3()).length();
    
           ;
           }
    } );
    
    // gltf.scene.traverse( function ( child )
    // {
    //     if ( child.isObject3D && loadArrayPost.includes(child) )
    //     {
    
    //     }
    // }  )
    
    
        //    const root = gltf.scene;
        //    const box = new THREE.Box3().setFromObject(root);
        //    const boxSize = box.getSize(new THREE.Vector3()).length();
        //    const boxCenter = box.getCenter(new THREE.Vector3());
        //    gltf.scene.scale.set(2.439,2.439,2.439)
        const scena = gltf.scene
           gltf.scene.position.set(0,0,0)
    
           //console.log(box)
    
    
    scene.add(gltf.scene)
    disposeHierarchy(gltf.scene,disposeNode)
    renderer.dispose()
    scena.traverse( function(jedan  ) {

        if ( jedan.material ) {
        
        jedan.material.dispose()
        jedan.material.map.dispose()
        
        // console.log(jedan.material);
        
    }
    

        } );
    scena.traverse( function(jedan  ) {

        if ( jedan.geometry ) {
        
        jedan.geometry.dispose()
        // console.log(jedan.geometry);
        
        
    }
})    
    //console.log(scene.children)
    updateAllMaterials()
    }
    )
}


if(!loadArrayGltf[4].includes(" ")){
    gltfLoader.load(
        loadArrayGltf[4],
    
    
       (gltf) =>
    {
    
        
    
       gltf.scene.traverse( function ( child )
    {
       if ( child.isMesh )
       {
           const box = new THREE.Box3().setFromObject(child);
    
           const boxSize = box.getSize(new THREE.Vector3()).length();
    
           ;
           }
    } );
    
    // gltf.scene.traverse( function ( child )
    // {
    //     if ( child.isObject3D && loadArrayPost.includes(child) )
    //     {
    
    //     }
    // }  )
    
    
        //    const root = gltf.scene;
        //    const box = new THREE.Box3().setFromObject(root);
        //    const boxSize = box.getSize(new THREE.Vector3()).length();
        //    const boxCenter = box.getCenter(new THREE.Vector3());
        //    gltf.scene.scale.set(2.439,2.439,2.439)
        const scena = gltf.scene
           gltf.scene.position.set(-0.244,0,0)
           
    
           //console.log(box)
    // gltf.scene.scale.set(40,40,40)
    
    scene.add(gltf.scene)
    
    disposeHierarchy(gltf.scene,disposeNode)
    renderer.dispose()
    const cleanMaterial = material => {
          
        material.dispose()
    
        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === 'object' && 'minFilter' in value) {
                
                value.dispose()
            }
        }
    }
    scena.traverse(object => {
        if (!object.isMesh) return
        
       
        object.geometry.dispose()
    
        if (object.material.isMaterial) {
            cleanMaterial(object.material)
        } else {
            // an array of materials
            for (const material of object.material) cleanMaterial(material)
        }
    })
    //console.log(scene.children)
    updateAllMaterials()
    }
    )
}

if(!loadArrayGltf[5].includes(" ")){
    gltfLoader.load(
        loadArrayGltf[5],
    
    
       (gltf) =>
    {
    
    
    
       gltf.scene.traverse( function ( child )
    {
       if ( child.isMesh )
       {
           const box = new THREE.Box3().setFromObject(child);
    
           const boxSize = box.getSize(new THREE.Vector3()).length();
    
           ;
           }
    } );
    
    // gltf.scene.traverse( function ( child )
    // {
    //     if ( child.isObject3D && loadArrayPost.includes(child) )
    //     {
    
    //     }
    // }  )
    
    
        //    const root = gltf.scene;
        //    const box = new THREE.Box3().setFromObject(root);
        //    const boxSize = box.getSize(new THREE.Vector3()).length();
        //    const boxCenter = box.getCenter(new THREE.Vector3());
        const scena = gltf.scene   
        gltf.scene.scale.set(2.439,2.439,2.439)
            gltf.scene.position.set(-0.244,0,0)
            gltf.scene.rotation.set(Math.PI*1.5, 0,0)
           //console.log(box)
    // gltf.scene.scale.set(40,40,40)
    
    scene.add(gltf.scene)
    
    disposeHierarchy(gltf.scene,disposeNode)
    renderer.dispose()
    const cleanMaterial = material => {
          
        material.dispose()
    
        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === 'object' && 'minFilter' in value) {
                
                value.dispose()
            }
        }
    }
    scena.traverse(object => {
        if (!object.isMesh) return
        
       
        object.geometry.dispose()
    
        if (object.material.isMaterial) {
            cleanMaterial(object.material)
        } else {
            // an array of materials
            for (const material of object.material) cleanMaterial(material)
        }
    })
    //console.log(scene.children)
    updateAllMaterials()
    }
    )
}

if(!loadArrayGltf[6].includes(" ")){
    gltfLoader.load(
        loadArrayGltf[6],
    
    
       (gltf) =>
    {

    
       gltf.scene.traverse( function ( child )
    {
       if ( child.isMesh )
       {
           const box = new THREE.Box3().setFromObject(child);
    
           const boxSize = box.getSize(new THREE.Vector3()).length();
    
           ;
           }
    } );
    
    // gltf.scene.traverse( function ( child )
    // {
    //     if ( child.isObject3D && loadArrayPost.includes(child) )
    //     {
    
    //     }
    // }  )
    
    
        //    const root = gltf.scene;
        //    const box = new THREE.Box3().setFromObject(root);
        //    const boxSize = box.getSize(new THREE.Vector3()).length();
        //    const boxCenter = box.getCenter(new THREE.Vector3());
        //    gltf.scene.scale.set(2.439,2.439,2.439)
           const scena = gltf.scene
        gltf.scene.scale.set(2.380,2.380,2.380)
        // gltf.scene.rotation.set(-Math.PI/2,0,0)
        gltf.scene.position.set(-0.244,0,0)
       
        
        
           //console.log(box)
    // gltf.scene.scale.set(40,40,40)
    
    scene.add(gltf.scene)
    disposeHierarchy(gltf.scene,disposeNode)
    renderer.dispose()
    const cleanMaterial = material => {
          
        material.dispose()
    
        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === 'object' && 'minFilter' in value) {
                
                value.dispose()
            }
        }
    }
    scena.traverse(object => {
        if (!object.isMesh) return
        
       
        object.geometry.dispose()
    
        if (object.material.isMaterial) {
            cleanMaterial(object.material)
        } else {
            // an array of materials
            for (const material of object.material) cleanMaterial(material)
        }
    })
    //console.log(scene.children)
    updateAllMaterials()
    
    }
    )
   
}
if(!loadArrayGltf[7].includes(" ")){
    gltfLoader.load(
        loadArrayGltf[7],
    
    
       (gltf) =>
    {
    
 
    
    
       gltf.scene.traverse( function ( child )
    {
       if ( child.isMesh )
       {
           const box = new THREE.Box3().setFromObject(child);
    
           const boxSize = box.getSize(new THREE.Vector3()).length();
    
           ;
           }
    } );
    
    // gltf.scene.traverse( function ( child )
    // {
    //     if ( child.isObject3D && loadArrayPost.includes(child) )
    //     {
    
    //     }
    // }  )
    
    
        //    const root = gltf.scene;
        //    const box = new THREE.Box3().setFromObject(root);
        //    const boxSize = box.getSize(new THREE.Vector3()).length();
        //    const boxCenter = box.getCenter(new THREE.Vector3());
        //    gltf.scene.scale.set(2.439,2.439,2.439)
        const scena = gltf.scene
           gltf.scene.position.set(0,0,0)
        gltf.scene.scale.set(2.439,2.439,2.439)
           

           //console.log(box)
    
    
    scene.add(gltf.scene)
    scene.add(gltf.scene)
    disposeHierarchy(gltf.scene,disposeNode)
    renderer.dispose()
    const cleanMaterial = material => {
          
        material.dispose()
    
        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key]
            if (value && typeof value === 'object' && 'minFilter' in value) {
                
                value.dispose()
            }
        }
    }
    scena.traverse(object => {
        if (!object.isMesh) return
        
       
        object.geometry.dispose()
    
        if (object.material.isMaterial) {
            cleanMaterial(object.material)
        } else {
            // an array of materials
            for (const material of object.material) cleanMaterial(material)
        }
    })
   
    //console.log(scene.children)
    updateAllMaterials()
    }
    )
}

}









/**
 * Lights
 */




// gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity')
// gui.add(directionalLight.position, 'x').min(-5).max(5).step(0.001).name('lightX')
// gui.add(directionalLight.position, 'y').min(-5).max(5).step(0.001).name('lightY')
// gui.add(directionalLight.position, 'z').min(-5).max(5).step(0.001).name('lightZ')


/**
 * Sizes
 */


window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio*1, 2))
})

/**
 * Camera
 */
// Base camera





// gui.add(renderer, 'toneMapping',{
//     No: THREE.NoToneMapping,
//     Linear: THREE.LinearToneMapping,
//     Reinhard: THREE.ReinhardToneMapping,
//     Cineon: THREE.CineonToneMapping,
//     ACESFilmic: THREE.ACESFilmicToneMapping
// })
// .onFinishChange(() =>
// {
//     renderer.toneMapping = Number(renderer.toneMapping)
//     updateAllMaterials()
// })
// gui.add(renderer, 'toneMappingExposure').min(0).max(10).step(0.001)

/**
 * Animate
 */
/**
 * VIEWS
 */
//  let Btn = document.querySelector('.Front');
//  Btn.addEventListener('click', () => {
//      frontview();
//      controls.autoRotate=false;
//  })
//  let Btn1 = document.querySelector('.Back');
//  Btn1.addEventListener('click', () => {
//      backview();
//      controls.autoRotate=false;
//  })
//  let Btn2 = document.querySelector('.Top');
//  Btn2.addEventListener('click', () => {
//      Top();
//      controls.autoRotate=false;
//  })
//  let Btn3 = document.querySelector('.Bottom');
//  Btn3.addEventListener('click', () => {
//      Bottom();
//      controls.autoRotate=false;
//  })
//  let Btn4 = document.querySelector('.FullRight');
//  Btn4.addEventListener('click', () => {
//      Right();
//      controls.autoRotate=false;
//  })
//  let Btn5 = document.querySelector('.FullLeft');
//  Btn5.addEventListener('click', () => {
//      Left();
//      controls.autoRotate=false;
//  })
 
//  let Btn7= document.querySelector('.Left45');
//  Btn7.addEventListener('click', () => {
//      leftsideview();
//      controls.autoRotate=false;
//  })
//  let Btn8= document.querySelector('.Right45');
//  Btn8.addEventListener('click', () => {
//      rightsideview();
//      controls.autoRotate=false;
//  })
//  let Btn9 = document.querySelector('.Bottom45');
//  Btn9.addEventListener('click', () => {
//      bottomview();
//      controls.autoRotate=false;
//  })
//  let Btn10 = document.querySelector('.Top45');
//  Btn10.addEventListener('click', () => {
//      topview();
//      controls.autoRotate=false;
//  })
//  let Btn11 = document.querySelector('.AR');
//  Btn11.addEventListener('click', () => {
//      AutoRotate();
//  })
 
 
//  function frontview() {
//      const coords = {x : camera.position.x, y: camera.position.y, z:camera.position.z}
//      new TWEEN.Tween(coords)
//      .to({ x: 0, y: 0, z:-1})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, coords.z),
    
//      )
//      .start();
     
//  }
//  function backview(){
//      const coords = {x : camera.position.x, y: camera.position.y,z:camera.position.z}
//      new TWEEN.Tween(coords)
//      .to({ x: 0, y: 0, z:1})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, coords.z),
    
//      )
//      .start();
     
//  }
//  function leftsideview(){
//      const coords = {x : camera.position.x, y: camera.position.y}
//      new TWEEN.Tween(coords)
//      .to({ x: 1.5, y: 1, z:-4})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, camera.position.z),
     
 
  
//      )
//      .start();
     
     
//  }
//  function rightsideview(){
//      const coords = {x : camera.position.x, y: camera.position.y}
//      new TWEEN.Tween(coords)
//      .to({ x: -1.5, y: 1, z:-4})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, camera.position.z),
     
//      )
//      .start();
     
//  }
//  function bottomview(){
//      const coords = {x : camera.position.x, y: camera.position.y}
//      new TWEEN.Tween(coords)
//      .to({ x: 0, y: -1.5, z:-4})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, camera.position.z),
     
//      )
//      .start();
    
//  }
//  function topview(){
//      const coords = {x : camera.position.x, y: camera.position.y}
//      new TWEEN.Tween(coords)
//      .to({ x: 0, y: 1.5, z:-4})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, camera.position.z),
     
//      )
//      .start();
 
//  }
//  function Top(){
//      const coords = {x : camera.position.x, y: camera.position.y,z:camera.position.z}
//      new TWEEN.Tween(coords)
//      .to({ x: 0, y: 1, z:-0.05})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, coords.z),
    
//      )
//      .start();
     
//  }
//  function Bottom(){
//      const coords = {x : camera.position.x, y: camera.position.y,z:camera.position.z}
//      new TWEEN.Tween(coords)
//      .to({ x: 0, y: -1, z:-0.05})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, coords.z),
    
//      )
//      .start();
     
//  }
//  function Right(){
//      const coords = {x : camera.position.x, y: camera.position.y,z:camera.position.z}
//      new TWEEN.Tween(coords)
//      .to({ x: -1.5, y: 0, z:-0.05})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, coords.z),
    
//      )
//      .start();
     
//  }
//  function Left(){
//      const coords = {x : camera.position.x, y: camera.position.y,z:camera.position.z}
//      new TWEEN.Tween(coords)
//      .to({ x:1.5, y: 0, z:-0.05})
//      .onUpdate(() =>
//        camera.position.set(coords.x, coords.y, coords.z),
    
//      )
//      .start();
     
//  }
//  function AutoRotate()
// {
    
//     controls.autoRotate=true;
//     controls.autoRotateSpeed=4;
// }

var id;
function animate() {
    
       
        renderer.render( scene, camera );
    
    
  }
  







const tick = () =>
{
    // Update controls
        
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
    
}
// WebGLRenderList()
// WebGLRenderLists()
tick()
// function WebGLRenderList() {

// 	var renderItems = [];
// 	var renderItemsIndex = 0;

// 	var opaque = [];
// 	var transparent = [];

// 	function init() {

// 		renderItemsIndex = 0;
// 		renderItems.length = 0;

// 		opaque.length = 0;
// 		transparent.length = 0;

// 	}

// 	function push( object, geometry, material, z, group ) {

// 		var renderItem = renderItems[ renderItemsIndex ];

// 		if ( renderItem === undefined ) {

// 			renderItem = {
// 				id: object.id,
// 				object: object,
// 				geometry: geometry,
// 				material: material,
// 				program: material.program,
// 				renderOrder: object.renderOrder,
// 				z: z,
// 				group: group
// 			};

// 			renderItems[ renderItemsIndex ] = renderItem;

// 		} else {

// 			renderItem.id = object.id;
// 			renderItem.object = object;
// 			renderItem.geometry = geometry;
// 			renderItem.material = material;
// 			renderItem.program = material.program;
// 			renderItem.renderOrder = object.renderOrder;
// 			renderItem.z = z;
// 			renderItem.group = group;

// 		}

// 		( material.transparent === true ? transparent : opaque ).push( renderItem );

// 		renderItemsIndex ++;

// 	}

// 	function sort() {

// 		if ( opaque.length > 1 ) opaque.sort( painterSortStable );
// 		if ( transparent.length > 1 ) transparent.sort( reversePainterSortStable );

// 	}

// 	return {
// 		opaque: opaque,
// 		transparent: transparent,

// 		init: init,
// 		push: push,

// 		sort: sort
// 	};

// }
// function WebGLRenderLists() {

// 	var lists = {};

// 	function get( scene, camera ) {

// 		var hash = scene.id + ',' + camera.id;
// 		var list = lists[ hash ];

// 		if ( list === undefined ) {

// 			// console.log( 'THREE.WebGLRenderLists:', hash );

// 			list = new WebGLRenderList();
// 			lists[ hash ] = list;

// 		}

// 		return list;
       

// 	}

// 	function dispose() {

// 		lists = {};
//         scene = null
//         camera = null
//         renderer && renderer.renderLists.dispose()
//         renderer = null

// 	}

// 	return {
// 		get: get,
// 		dispose: dispose
// 	};

// }

