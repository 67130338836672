// let loadArrayPost=["MODEL_2","MODEL_20","MODEL_1","texture_1","MODEL_1","MODEL_2","MODEL_20","steel_spiral"]
var pathname = window.location.href;
console.log(pathname);
let request = {}
function URLToArray(url) {
    
    var pairs = url.substring(url.indexOf('?') + 1).split('&');
    for (var i = 0; i < pairs.length; i++) {
        if(!pairs[i])
            continue;
        var pair = pairs[i].split('=');
        request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
     }
     return request;
  console.log(request)

}

URLToArray(pathname)
console.log(request)
console.log(typeof request);
let string = JSON.stringify(request)
console.log(string);
console.log(typeof string);
string = string.substring(10,string.length)
console.log(string);
string = string.replace('}', '')
string = string.replaceAll('"', '')
console.log(string);

string = string.replaceAll('+','0')
console.log(string);

string = string.replaceAll('|',',')
console.log(string);
string = string.replaceAll(',0,',', ,')
console.log(string);


let ModelArray = string.split(" ")
console.log(string);
console.log(ModelArray);
ModelArray=string.split(",", 10)
console.log(ModelArray);
let loadArrayPost = [ModelArray[0],ModelArray[1],ModelArray[2],ModelArray[3],ModelArray[4],ModelArray[5],ModelArray[6],ModelArray[7]]

console.log(loadArrayPost);
var select;      
select = document.getElementById('leftmodels')
function onChange(){
    loadArrayPost[0] = select.options[select.selectedIndex].innerHTML;
          console.log(loadArrayPost[0] ) 
 }
var select1;      
select1 = document.getElementById('leftaccs')
function onChange1(){
    loadArrayPost[1] = select1.options[select1.selectedIndex].innerHTML;
          console.log(loadArrayPost[1] ) 
          
 }
var select2;      
select2 = document.getElementById('leftfer')
function onChange2(){
    loadArrayPost[2] = select2.options[select2.selectedIndex].innerHTML;
          console.log(loadArrayPost[2] ) 
 }
var select3;      
select3 = document.getElementById('hose')
function onChange3(){
    loadArrayPost[3] = select3.options[select3.selectedIndex].innerHTML;
          console.log(loadArrayPost[3] ) 
 }
var select4;      
select4 = document.getElementById('rightfer')
function onChange4(){
    loadArrayPost[4] = select4.options[select4.selectedIndex].innerHTML;
          console.log(loadArrayPost[4] ) 
 }
var select5;      
select5 = document.getElementById('rightmodels')
function onChange5(){
    loadArrayPost[5] = select5.options[select5.selectedIndex].innerHTML;
          console.log(loadArrayPost[5] ) 
 }
var select6;      
select6 = document.getElementById('rightaccs')
function onChange6(){
    loadArrayPost[6] = select6.options[select6.selectedIndex].innerHTML;
          console.log(loadArrayPost[6]) 
 }
 var select7;      
select7 = document.getElementById('rightaccs')
function onChange7(){
    loadArrayPost[7] = select6.options[select6.selectedIndex].innerHTML;
          console.log(loadArrayPost[7]) 
 }
// function Dispose(){
    
        
//         location.reload();
// }

 
export {loadArrayPost}